import React from "react";

class CheckoutStep1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parentName: "",
      parentEmail: "",
      students: [
        {
          studentName: "",
          studentEmail: "",
          school: "",
          age: "",
          grade: "",
          notes: "",
          goals: "",
          interestedClass: "",
        },
      ],
      maxStudentsReached: false,
    };
  }

  preemptiveOptionsRequest = () => {
    fetch("https://dev.bytesizelearning.org/step_one/", {
      method: "OPTIONS",
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error("OPTIONS request not successful");
        }
        return response.json();
      })
      .catch(error => {
        console.error("OPTIONS Error:", error);
      });
  };
  
  componentDidMount() {
    this.preemptiveOptionsRequest();
  }  

  isFormValid = () => {
    if (this.state.parentName === "" || this.state.parentEmail === "") {
      return false;
    }
    for (const student of this.state.students) {
      if (
        !student.studentName ||
        !student.studentEmail ||
        !student.school ||
        !student.grade ||
        !student.notes ||
        !student.goals
      ) {
        return false;
      }
    }

    console.log("All data is valid");
    return true;
  };

  handleInputChange = (e, index, name) => {
    const students = [...this.state.students];
    students[index] = {
      ...students[index],
      [name]: e.target.value || undefined,
    };
    this.setState({ students }, () => {
      const isValid = this.isFormValid();
      this.props.updateFormValidity(isValid);
    });
  };

  handleAddStudent = () => {
    if (this.state.students.length >= 5) {
      this.setState({ maxStudentsReached: true });
    } else {
      this.setState(
        (prevState) => ({
          students: [
            ...prevState.students,
            {
              studentName: "",
              studentEmail: "",
              school: "",
              age: "",
              grade: "",
              notes: "",
              goals: "",
            },
          ],
          maxStudentsReached: false,
        }),
        () => {
          const isValid = this.isFormValid();
          this.props.updateFormValidity(isValid);
        }
      );
    }
  };

  handleParentInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      const isValid = this.isFormValid();
      this.props.updateFormValidity(isValid);
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.isFormValid()) {
      const dataToSend = {
        parent: {
          parentName: this.state.parentName,
          parentEmail: this.state.parentEmail,
          isSubscribed: false,
        },
        students: this.state.students,
      };
      console.log(dataToSend);
  
      fetch("https://dev.bytesizelearning.org/step_one/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSend),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          console.log("Success:", data);

          if (data.order_id) {
            localStorage.setItem('order_id', data.order_id);
          }

          this.props.handleData(1, {
            ...this.state,
            isDataInvalid: !this.isFormValid(),
          });
          this.props.nextStep();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  

  handleRemoveStudent = (index) => {
    this.setState(
      (prevState) => ({
        students: prevState.students.filter((s, i) => i !== index),
      }),
      () => {
        const isValid = this.isFormValid();
        this.props.updateFormValidity(isValid);
      }
    );
  };

  render() {
    return (
      <section className="py-8 bg-white">
        <h2 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">
          Parent & Student Info
        </h2>
        <form
          onSubmit={this.handleSubmit}
          className="grid gap-4 sm:gap-6 grid-cols-2">
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Parent Name:
            <input
              type="text"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              name="parentName"
              value={this.state.parentName}
              onChange={this.handleParentInputChange}
              required
            />
          </label>
          <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Parent Email:
            <input
              type="email"
              name="parentEmail"
              id="parentEmail"
              value={this.state.parentEmail}
              onChange={this.handleParentInputChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
              required
            />
          </label>

          {this.state.students.map((student, index) => (
            <div key={index}>
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Student Name:
                <input
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  name={"studentName" + index}
                  value={student.studentName}
                  onChange={(e) =>
                    this.handleInputChange(e, index, "studentName")
                  }
                />
              </label>
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Student Email:
                <input
                  type="email"
                  name={"studentEmail" + index}
                  value={student.studentEmail}
                  onChange={(e) =>
                    this.handleInputChange(e, index, "studentEmail")
                  }
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                />
              </label>
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                School:
                <input
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  name={"school" + index}
                  value={student.school}
                  onChange={(e) => this.handleInputChange(e, index, "school")}
                />
              </label>
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Age:
                <input
                  type="number"
                  name={"age" + index}
                  id="age"
                  value={student.age}
                  onChange={(e) => this.handleInputChange(e, index, "age")}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                />
              </label>
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Grade:
                <input
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                  name={"grade" + index}
                  value={student.grade}
                  onChange={(e) => this.handleInputChange(e, index, "grade")}
                />
              </label>
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Notes:
                <textarea
                  name={"notes" + index}
                  id="notes"
                  value={student.notes}
                  onChange={(e) => this.handleInputChange(e, index, "notes")}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                />
              </label>
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Goals/Interested Class:
                <textarea
                  name={"goals" + index}
                  id="goals"
                  value={student.goals}
                  onChange={(e) => this.handleInputChange(e, index, "goals")}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
                />
              </label>
              <button
                type="button"
                className="mt-4 bg-red-500 border-2 border-red-500 hover:border-red-700 hover:text-red-700 rounded-lg hover:bg-white duration-200 text-white font-bold py-2 px-4 rounded"
                onClick={() => this.handleRemoveStudent(index)}>
                Remove Student
              </button>
            </div>
          ))}
          <div>


            {this.state.students.length < 5 && (
              <button
                type="button"
                className="mt-4 mr-2 bg-dodger hover:bg-white border-2 border-dodger hover:text-dodger rounded-lg duration-200 text-white font-bold py-2 px-4 rounded"
                onClick={this.handleAddStudent}>
                Add Another Student
              </button>
            )}
                      </div>
            <button
              type="submit"
              className={`col-span-2 w-full px-5 py-2.5 sm:py-3.5 text-sm bg-dodger font-medium text-center rounded-lg border-2 focus:outline-none duration-200 ${
                !this.isFormValid()
                  ? "text-gray-500 bg-gray-200 cursor-not-allowed"
                  : "text-white bg-doder hover:bg-gray-200 hover:text-dodger focus:ring-4 focus:ring-primary-300"
              }`}>
              Next
            </button>
        </form>
      </section>
    );
  }
}

export default CheckoutStep1;
