import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';


// Core Components of all pages
import Navigation from './components/UniversalComponents/Navigation.jsx';
import Footer from './components/UniversalComponents/Footer.jsx';

// Core Pages
import Home from './components/Home.jsx';
import About from './components/About.jsx';
import Classes from './components/Classes.jsx';
import Contact from './components/Contact.jsx';

// Classes Pages
import Programming from './components/ClassesPages/Programming.jsx';
import GameDesign from './components/ClassesPages/GameDesign.jsx';
import GraphicDesign from './components/ClassesPages/GraphicDesign.jsx';
import MinecraftEngineering from './components/ClassesPages/MinecraftEngineering.jsx';
import VideoEditing from './components/ClassesPages/VideoEditing.jsx';
import MusicProduction from './components/ClassesPages/MusicProduction.jsx';

// Buy A Class and Redeem a Ticket Pages
//import BuyClass from './components/BuyClass.jsx';
// import RedeemTicket from './components/RedeemTicket.jsx';
import CheckoutPage from "./components/CheckoutComponents/CheckoutPage.jsx";

//More Pages
import Partners from './components/Partners.jsx';
import ByteSizeBuddies from './components/ByteSizeBuddies.jsx';
// import DemoBooking from './components/DemoBooking.jsx';
import ByteSizeNews from './components/ByteSizeNews.jsx';

// 404 Pages, Legal Pages, and other pages
import NotFoundError from './components/UniversalComponents/NotFoundError.jsx';

// Legal Pages
import PrivacyPolicy from './components/LegalPages/PrivacyPolicy.jsx';
import EndUserlicenseAgreement from './components/LegalPages/EndUserLicenseAgreement.jsx';
import TermsAndConditions from './components/LegalPages/TermsAndConditions.jsx';
import WebsiteDisclaimer from './components/LegalPages/WebsiteDisclaimer.jsx';

//Backend Components for Purchase and Redeem (Stripe)
import Purchase from './components/Purchase.jsx';
// import CheckoutForm from './components/CheckoutForm.jsx';
// import NewCheckoutForm from './components/NewCheckoutForm.jsx';
import CheckoutWrapper from './components/CheckoutWrapper.jsx';
// import Temp from './components/UniversalComponents/Temp.jsx';
import PaymentAttempt from './components/PaymentAttempt.jsx';
import Success from './components/Success.jsx';
import Failure from './components/Failure.jsx';
import Summary from './components/CheckoutSummary.jsx';

import 'flowbite/dist/flowbite.min.js';
import 'flowbite-react';
//import BuyAttempt from './components/BuyAttempt.jsx';
import ReactGA from 'react-ga';

function usePageViews() {
  const location = useLocation();
  useEffect(() => {
    ReactGA.initialize('UA-208283512-1');
    console.log("navigating to " + location.pathname + location.search);
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
}

function PageViewTracker() {
  usePageViews();
  return null;
}

function App() {

  // Determine which component to render based on the current URL
  const currentPath = window.location.pathname;
  let componentToRender;

  if (currentPath === "/checkout") {
    componentToRender = (
      <CheckoutWrapper />
    );
  } else if (currentPath === "/test") { 
    componentToRender = (
      <CheckoutPage />
    );
  } else {
    componentToRender = (
      <>
        <Navigation />
          <div>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/classes" element={<Classes />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/programming" element={<Programming />} />
              <Route path="/gamedesign" element={<GameDesign />} />
              <Route path="/graphicdesign" element={<GraphicDesign />} />
              <Route path="/engineering" element={<MinecraftEngineering />} />
              <Route path="/videoediting" element={<VideoEditing />} />
              <Route path="/musicproduction" element={<MusicProduction />} />
              <Route path="/buy" element={<Purchase />} />
              <Route path="/paymentattempt" element={<PaymentAttempt />} />
              <Route path="/success" element={<Success />} />
              <Route path="/failure" element={<Failure />} />
              <Route path="/purchase" element={<Purchase />} />
              {/* <Route path="/redeem" element={<RedeemTicket />} /> */}
              <Route path="/partners" element={<Partners />} />
              <Route path="/bytesizebuddies" element={<ByteSizeBuddies />} />
              <Route path="/bytesizenews" element={<ByteSizeNews />} />
              <Route path="/termsandconditions" element={<TermsAndConditions />} />
              <Route path="/summary" element={<Summary />} />

              {/* Legal Pages */}
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="/enduserlicenseagreement" element={<EndUserlicenseAgreement />} />
              <Route path="/termsandconditions" element={<TermsAndConditions />} />
              <Route path="/websitedisclaimer" element={<WebsiteDisclaimer />} />

              <Route path="/404" element={<NotFoundError />} />
              <Route path="*" element={<NotFoundError />} />
            </Routes>
          </div>
        <Footer />
      </>
    );
  }

  return (
    <Router>
      <PageViewTracker />
      <div>{componentToRender}</div>
    </Router>
  );
}

export default App;
