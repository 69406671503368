import React from "react";
import AIThumbnail from "../../assets/images/ByteSizeNews/AIThumbnail.png";
import EducationPurposeThumbnail from "../../assets/images/ByteSizeNews/EducationPurposeThumbnail.png";

function ByteSizeBlogsCTA() {
  return (
    <>
      <section class="bg-white ">
        <div class="max-w-screen-xl px-4 py-8 mx-auto lg:px-6 sm:py-16 lg:py-24">
          <div class="max-w-2xl mx-auto text-center">
            <h2 className="mb-4 text-3xl lg:text-4xl tracking-tight font-extrabold text-gray-900">
              ByteSize Blogs
            </h2>
            <p className="font-light text-gray-500 sm:text-xl">
              Thinking hard about the future of education, technology, and
              society
            </p>
          </div>

          <div class="grid grid-cols-1 gap-4 mt-8 xl:gap-12 md:grid-cols-2">
            <div class="p-5 space-y-4 bg-white border border-gray-200 rounded-lg shadow-md lg:p-8  ">
              <img
                class="object-cover w-full rounded-lg shadow-lg"
                src={AIThumbnail}
                alt=""
              />

              <h3 class="text-lg font-bold leading-tight text-gray-900 ">
                Education, AI, and Astronaut Philosophers?
              </h3>
              <p class="text-base font-normal text-gray-500 ">
                So you're wondering what the world looks like after AI has taken
                over, huh? Let's take a look at the future of AI and education
                from a March 2023 lens.
              </p>

              <div class="flex flex-col gap-4 sm:flex-row md:flex-col lg:flex-row lg:items-center">
                <a
                  href="https://blogs.bytesizelearning.org/ai-and-the-future-of-education"
                  title=""
                  class="px-5 py-2.5 text-sm justify-center font-medium text-gray-900 inline-flex items-center focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-dodger duration-200 focus:z-10 focus:ring-4 focus:ring-gray-200      "
                  role="button"
                >
                  Read Blog
                  <svg
                    aria-hidden="true"
                    class="w-5 h-5 ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>
              </div>
            </div>

            <div class="p-5 space-y-4 bg-white border border-gray-200 rounded-lg shadow-md lg:p-8  ">
              <img
                class="object-cover w-full rounded-lg shadow-lg"
                src={EducationPurposeThumbnail}
                alt=""
              />

              <h3 class="text-lg font-bold leading-tight text-gray-900 ">
                What is the point of Education?
              </h3>
              <p class="text-base font-normal text-gray-500 ">
                Why did education exist in the first place? What is the point of
                it anyway? Let's dig into the functions of education and see
                what we can find.
              </p>

              <div class="flex flex-col gap-4 sm:flex-row md:flex-col lg:flex-row lg:items-center">
                <a
                  href="https://blogs.bytesizelearning.org/what-is-the-purpose-of-education-in-2023"
                  title=""
                  class="px-5 py-2.5 text-sm justify-center font-medium text-gray-900 inline-flex items-center focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-dodger duration-200 focus:z-10 focus:ring-4 focus:ring-gray-200      "
                  role="button"
                >
                  Read Blog
                  <svg
                    aria-hidden="true"
                    class="w-5 h-5 ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>

          <div class="mt-8 text-center">
            <a
              href="https://blogs.bytesizelearning.org/"
              title=""
              class="inline-flex items-center text-lg font-medium text-dodger hover:underline "
            >
              View all ByteSize blogs
              <svg
                aria-hidden="true"
                class="w-5 h-5 ml-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

export default ByteSizeBlogsCTA;
