import React from "react";
import PuberryImage from "../../assets/images/Partnerships/PuberryImage.png";
import PuberryLogo from "../../assets/images/Partnerships/PuberryLogo.png";

function Puberry() {
  return (
    <div id="puberry">
      <section className="bg-puberry-gray">
        <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
          <img
            className="w-full"
            src={PuberryImage}
            alt="Puberry: puberty education for kids and teens"
          />
          <div className="md:mt-0">
            <div className="flex items-center justify-center max-w-sm mx-auto lg:max-w-none lg:mx-0">
              <img
                className="object-cover w-40 h-40 mb-12"
                src={PuberryLogo}
                alt="Puberry: Puberty education for kids and teens"
              />
            </div>

            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">
              Puberry: Puberty Education for Kids and Teens
            </h2>
            <p className="mb-6 font-light text-gray-500 md:text-lg">
              Join our freinds at Puberry to begin or imporve your child's education through their journey through puberty.{" "}
            </p>

            {/* <span className="inline-flex rounded-md shadow-sm">
                            <p className="mb-6 text-black font-bold md:text-lg">Discount code for 10% off:</p>
                            <p className="mb-6 mx-4 font-bold text-puberry-blue md:text-xl">BYTESIZE10</p>
                        </span> */}
            <div className="flex flex-col space-y-4 sm:flex-row sm:space-x-4 sm:space-y-2">
              <a
                href="https://www.puberry.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center border-2 bg-white text-puberry-blue border-puberry-blue hover:bg-puberry-gray focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">
                Learn More!
              </a>
              <a
                href="https://puberry.org/collections/all"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-flex items-center border-2 border-puberry-blue hover:text-puberry-blue text-white bg-puberry-blue hover:bg-puberry-gray focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">
                Purchase a Class
                <svg
                  className="ml-2 -mr-1 w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Puberry;
