import React from "react";

function MissionVision() {
    return (
        <div id="missionvision">
            <section className="bg-white">
    <div className="py-8 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
    <h2 className="mb-4 text-4xl tracking-tight font-extrabold leading-tight text-gray-900 text-center">Our Values</h2>
        <div className="mx-auto max-w-screen-sm text-left">
            
            <p className="mb-6 font-light text-gray-600 md:text-lg"><strong className="text-dodger underline">Mission Statement: </strong> We strive to offer unique and effective learning experiences in a wide range of technology topics with a creative project-based learning approach at accessible rates and modalities in order to develop holistic, applicable knowledge for beginner and intermediate learners alike.</p>
            <p className="mb-6 font-light text-gray-600 md:text-lg"><strong className="text-dodger underline">Vision: </strong> Transcending barriers to bridge the gap between interest and real-world skills for all children.</p>
        </div>
    </div>
</section>
        </div>
    );
}

export default MissionVision;