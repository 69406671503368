import React from "react";
import ReactDOM from "react-dom";
import { initializeApp } from "firebase/app";
import 'firebase/analytics';

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// import "flowbite-react";
// import 'flowbite';


const firebaseConfig = {
    apiKey: "AIzaSyCyQoL5TXmpnXzqVSzr9ehzhioEeHshhKI",
    authDomain: "bytesizelearningwebsite.firebaseapp.com",
    projectId: "bytesizelearningwebsite",
    storageBucket: "bytesizelearningwebsite.appspot.com",
    messagingSenderId: "85734638580",
    appId: "1:85734638580:web:333d26c001e7825d162fa1",
    measurementId: "G-TYRGGS6RRE",
  };

initializeApp(firebaseConfig);

ReactDOM.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
