import React from "react";
import MixxerLogo from "../../assets/images/Partnerships/mixxer-logo.png";
import FieldDayLogo from "../../assets/images/Partnerships/fieldDay.png";
import WFUWKDN from "../../assets/images/Partnerships/WKDN.jpg";
import WFUSUMMER1 from "../../assets/images/Partnerships/WFUSUMMER.png";
import WFUSUMMER2 from "../../assets/images/Partnerships/WFUSUMMER2.png";
import BloominChefLogo from "../../assets/images/Partnerships/bloomincheflogo.png";
import PuberryLogo from "../../assets/images/Partnerships/PuberryLogo.png";

function PartnershipsHero() {
    return (
        <div className="bg-white">
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div className="mx-auto max-w-screen-sm text-center lg:mb-16 mb-8">
                    <h2 className="mb-4 text-3xl lg:text-4xl tracking-tight font-extrabold text-gray-900">ByteSize Partners</h2>
                    <p className="font-light text-gray-500 sm:text-xl">We're building a community of like-minded organizations that are passionate about education, society, and a better future.</p>
                </div>
                    <div className="mx-auto max-w-screen-xl px-6">
                        <div className="grid grid-cols-2 gap-8 text-gray-500 sm:gap-12 md:grid-cols-3 lg:grid-cols-5 items-center">
                            <a href="/" className="flex justify-center items-center">
                                <img src={MixxerLogo} alt="Mixxer makerspace in winston-salem, north carolina teaching children and teens fun, useful skills" />
                            </a>

                            <a href="/" className="flex justify-center items-center">
                                <img src={FieldDayLogo} alt="Field Day is a summer camp for kids based in the triangle region of North Carolina" />
                            </a>

                            <a href="/" className="flex justify-center items-center">
                                <img src={WFUWKDN} alt="WKDN is a student-run radio station at Wake Forest University" />
                            </a>
                            <a href="/" className="flex justify-center items-center">
                                <img src={WFUSUMMER1} alt="Wake Forest University Summer Programs" />
                            </a>
                            <a href="/" className="flex justify-center items-center">
                                <img src={WFUSUMMER2} alt="Wake Forest University Summer Programs" />
                            </a>
                            <a href="/" className="flex justify-center items-center">
                                <img src={BloominChefLogo} alt="Blooming Chef is a cooking school for kids in the triangle region of North Carolina" />
                            </a>
                            <a href="/" className="flex justify-center items-center w-[7rem]">
                                <img src={PuberryLogo} alt="Puberry is a puberty education platform for children and teens to better understand their body in their journey through puberty" />
                            </a>
                            
                        </div>
                    </div>
            </div>
        </div>
    );
}

export default PartnershipsHero;