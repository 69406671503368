import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";

function NewsletterCTA() {
  const form = useRef();

  const [show, toggleShow] = useState(false);
  const [loading, setLoading] = useState(false);  // New state to handle button's disabled status

  useEffect(() => {
    if (!show) {
      form.current.reset();
    }
  }, [show]);

  const sendEmail = async (e) => {
    e.preventDefault();

    setLoading(true);  // Disable button and show loading

    const email = form.current["member_email"].value; // Grab the email value using form ref

    if (form.current.checkValidity()) {
      try {
        const response = await fetch("https://dev.bytesizelearning.org/subscribers/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: email }),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        await emailjs.sendForm(
          "service_p10xb2d",
          "template_dsqsorb",
          form.current,
          "JoQcn25Gezs4At60T"
        );

        toggleShow(true);
      } catch (error) {
        console.error(error);
      }
    } else {
      form.current.reportValidity();
    }

    setLoading(false);  // Enable button and hide loading
  };

  return (
    <div>
      <section className="bg-white">
        <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6 ">
          <div className="mx-auto max-w-screen-md text-center">
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">
              Get Updates on ByteSize Learning
            </h2>
            <p className="mb-6 font-light text-gray-500 md:text-lg">
              Get the sneak-peek on updates in technology education for your
              child
            </p>
            <form
              ref={form}
              onSubmit={sendEmail}
              className="mx-auto max-w-screen-sm">
              <div className="flex items-center mb-3">
                <div className="relative mr-3 w-full">
                  <label
                    id="member_email"
                    htmlFor="member_email"
                    className="hidden mb-2 text-sm font-medium text-dodger">
                    Email address
                  </label>
                  <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <label
                      htmlFor="member_email"
                      className="block mb-2 text-sm font-medium text-gray-900"></label>
                  </div>
                  <input
                    type="email"
                    id="member_email"
                    name="member_email"
                    className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5     "
                    placeholder="bytesizelearner@gmail.com"
                    required
                  />

                  <div></div>
                </div>
                <div>
                  <button
                    type="submit"
                    onClick={sendEmail}
                    disabled={loading} // Disable button while loading
                    className={`py-3 px-5 text-sm font-medium text-center border-2 border-dodger hover:bg-white hover:text-dodger duration-200 text-white rounded-lg bg-dodger sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 ${
                      loading ? "bg-gray-400 hover:bg-gray-400 hover:cursor-not-allowed" : ""
                    } ${show ? "hidden" : ""}`}>
                    {" "}
                    {loading ? "Loading..." : "Subscribe"}
                  </button>
                  <p
                    className={`py-3 px-5 text-sm font-medium text-center hover:bg-white hover:text-dodger duration-200 text-dodger rounded-lg bg-white sm:w-fit  focus:ring-4 focus:outline-none focus:ring-primary-300    ${
                      !show ? "hidden" : ""
                    }`}>
                    You have been subscribed! Thank you!
                  </p>
                </div>
              </div>
              <div className="text-sm font-medium text-left text-gray-500">
                Hear more about what you can do right for your child.
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}
export default NewsletterCTA;
