import React from "react";
import { useLocation } from "react-router-dom";
import Success from "./Success";
import Failure from "./Failure";

function PaymentAttempt() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const redirectStatus = queryParams.get("redirect_status");

    if (redirectStatus === "succeeded") {
        return <Success />;
    } else if (redirectStatus === "failed") {
        return <Failure />;
    } else {
        return <Failure />;
    }
}

export default PaymentAttempt;
