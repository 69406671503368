import React from "react";

function ArticleCard(props) {
  return (
    <article className="p-4 mx-auto max-w-sm bg-white rounded-lg shadow-md border border-gray-200 ">
      <a href={props.link_two} target="_blank" rel="noopener noreferrer">
        <img
          className="mb-5 rounded-lg object-contain"
          src={props.image}
          alt={props.alt}
        />
      </a>
      <div className="flex items-center mb-3 space-x-2">
        <div className="font-medium">
          <div>{props.location}</div>
          <div className="text-sm font-normal text-gray-500">{props.date}</div>
        </div>
      </div>
      <h3 className="mb-2 text-xl font-bold tracking-tight text-gray-900 lg:text-2xl">
        <a href={props.link} target="_blank" rel="noopener noreferrer">
          {props.title}
        </a>
      </h3>
      <p className="mb-3 font-light text-gray-500">{props.description}</p>
      <a
        href={props.link}
        target="_blank"
        rel="noopener noreferrer"
        className="inline-flex items-center font-medium text-primary-600 hover:text-primary-800 hover:no-underline"
      >
        {props.button_text}
        <svg
          className="mt-px ml-1 w-4 h-4"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
            clipRule="evenodd"
          ></path>
        </svg>
      </a>
    </article>
  );
}

export default ArticleCard;
