import React from "react";
import { CheckBadgeIcon, HeartIcon, HandRaisedIcon } from "@heroicons/react/24/solid";

function BuddiesCTA() {
    return (
        <div>
            <section className="bg-white">
                <div className="px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
                    <div className="max-w-screen-md">
                        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">Work with us - make a difference today!</h2>
                        <p className="mb-8 font-light text-gray-500 sm:text-xl">The ByteSize Buddies program is the hallmark of our company vision and the reason we do what we do. It is not possible without support from all over.</p>
                        <ul className="pt-8 my-7 space-y-5 border-t border-gray-200 ">
                            <li className="flex space-x-3">
                                <CheckBadgeIcon className="flex-shrink-0 w-5 h-5 text-dodger" />
                                <span className="text-base font-medium leading-tight text-gray-900">Sponsor a ByteSize buddy</span>
                            </li>
                            <li className="flex space-x-3">
                                <CheckBadgeIcon className="flex-shrink-0 w-5 h-5 text-dodger" />
                                <span className="text-base font-medium leading-tight text-gray-900">Donate a laptop or old computer equipment</span>
                            </li>
                            <li className="flex space-x-3">
                                <CheckBadgeIcon className="flex-shrink-0 w-5 h-5 text-dodger" />
                                <span className="text-base font-medium leading-tight text-gray-900">Connect us with a potential mentor or buddy</span>
                            </li>
                            <li className="flex space-x-3">
                                <CheckBadgeIcon className="flex-shrink-0 w-5 h-5 text-dodger" />
                                <span className="text-base font-medium leading-tight text-gray-900">Talk to us about the program and how you can get involved!</span>
                            </li>
                        </ul>
                        <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
                            <a href="/contact" className="inline-flex items-center justify-center hover:text-dodger duration-200 text-white bg-dodger border-2 hover:border-dodger px-4 py-2.5 text-base font-medium text-center border-gray-300 rounded-lg hover:bg-white focus:ring-4 focus:ring-gray-100   ">
                                <HandRaisedIcon className="mr-2 flex-shrink-0 w-5 h-5" />
                                Reach out
                            </a>
                            <a href="/contact" className="inline-flex items-center justify-center px-4 py-2.5 text-base font-medium text-center text-gray-900 border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:ring-gray-100   ">
                                <HeartIcon className="mr-2 flex-shrink-0 w-5 h-5 text-red-500" />
                                Donate today
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default BuddiesCTA;
