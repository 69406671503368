import React from "react";
import { ComputerDesktopIcon, GiftIcon, UsersIcon   } from "@heroicons/react/24/solid";

function BuddiesAbout() {
    return (
        <div>
            <section className="overflow-hidden relative bg-white">
                <div className="gap-8 py-8 px-4 mx-auto max-w-screen-xl lg:py-16">
                    <div>
                        <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl">The ByteSize Buddies Program</h1>
                        <p className="my-8 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl">Investing with our time, resources, and expertise to those who need it</p>
                        <div className="gap-16 items-center sm:flex mx-6 my-6">
                            <div className="text-gray-500 sm:mb-0 mt-4">
                            <ComputerDesktopIcon className="mb-3 w-7 h-7 text-dodger"/>
                                <h2 className="mb-3 text-xl font-semibold text-gray-900">Free Computer</h2>
                                <p className="font-light">We know that sometimes a spark makes all the difference. A free laptop with pre-installed software give our buddies the digital tools to succeed!</p>
                            </div>
                            <div className="text-gray-500 mt-4">
                            <GiftIcon className="mb-3 w-7 h-7 text-dodger"/>
                                <h2 className="mb-3 text-xl font-semibold text-gray-900">Free Classes</h2>
                                <p className="font-light"> Our buddies get lifetime access* to ByteSize Learning courses where we hope they learn all we have to offer!</p>
                            </div>
                            <div className="mb-8 text-gray-500 sm:mb-0 mt-4">
                                <UsersIcon className="mb-3 w-7 h-7 text-dodger"/>
                                <h2 className="mb-3 text-xl font-semibold text-gray-900">Mentorship</h2>
                                <p className="font-light">We connect each of our buddies with a young professional in a technology industry to offer them guidance and motivation towards a bright future</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default BuddiesAbout;