import React from "react";
import Mission from "./AboutComponents/MissionVison";
import FAQ from "./AboutComponents/FAQs";
import NewsletterCTA from "./UniversalComponents/NewsletterCTA"
import OurFounder from "./AboutComponents/OurFounder";

function About() {
    return (
        <div id="about">
            <FAQ />
            <OurFounder />
            <Mission />
            <NewsletterCTA />
        </div>
    );
}
export default About;
