import React, { useEffect, useState } from "react";

import NewCheckoutForm2 from "./NewCheckoutForm2.jsx";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

// import ByteSizeLogo from "../assets/bytesize-logo.png";

const stripePromise = loadStripe("pk_live_51HztwsLs3GQydstigq4wHAHrt0S6DwELL5m2c6GYgm8AVAdxZz2KSThwm7YZCNN4VVrXAWycdLkBx2oQFNrfXnAL00efLDikhk");

const orderData = {
  order_id: "123456789",
  class_info: {
    title: "Class Title",
    time: "10:00 AM - 11:00 AM",
    startDate: "2021-01-01",
    endDate: "2021-01-01",
  },
};

// add orderData to local storage
localStorage.setItem("orderData", JSON.stringify(orderData));

function OrderSummary() {
  const [orderData, setOrderData] = useState(null);

  useEffect(() => {
    // Assuming the order data in local storage as "orderData"
    const data = localStorage.getItem("orderData");
    if (data) {
      setOrderData(JSON.parse(data));
    }
  }, []);

  if (!orderData) return <div>Loading...</div>;

  return (
    <div className="flex flex-col text-center">
      {/* include bytesize logo */}
      {/* <img src={ByteSizeLogo} alt="ByteSize Logo" className="w-1/4 mx-auto" /> */}
      <h1 className="text-2xl font-bold text-gray-800">Order Summary</h1>

      <span className="font-semibold text-gray-600 flex flex-col items-center px-4">
        Order ID:
        <p className="text-sm font-light text-gray-800 px-4 mb-16">
          {orderData.order_id}
        </p>
      </span>
      <span className="font-semibold text-gray-600 flex flex-row justify-between px-4">
        Title:
        <p className="text-lg font-light text-gray-800 px-4 flex items-center">
          {orderData.class_info.title}
        </p>
      </span>
      <span className="font-semibold text-gray-600 flex flex-row justify-between px-4">
        Time:
        <p className="text-lg font-light text-gray-800 px-4 flex items-center">
          {orderData.class_info.time}
        </p>
      </span>
      <span className="font-semibold text-gray-600 flex flex-row justify-between px-4">
        Start Date:
        <p className="text-lg font-light text-gray-800 px-4 flex items-center">
          {orderData.class_info.startDate}
        </p>
      </span>
      <span className="font-semibold text-gray-600 flex flex-row justify-between px-4">
        End Date:
        <p className="text-lg font-light text-gray-800 px-4 flex items-center">
          {orderData.class_info.endDate}
        </p>
      </span>
    </div>
  );
}

function CheckoutWrapper() {
  return (
    <>
      <div className="bg-white flex-col flex text-center pt-10">
        <h1 className="text-4xl font-bold text-gray-800">Checkout</h1>
        <h3 className="text-lg font-light text-gray-800">
          If you are having trouble purchasing a class, please reach out to
          andrew@bytesizelearning.org{" "}
        </h3>
      </div>
      <section className="bg-white flex flex-col md:flex-row pt-10">
        {/* Order Summary on the left */}
        <div className="w-full md:w-1/2 text-center p-4">
          <OrderSummary />
        </div>

        {/* Checkout form on the right */}
        <div className="w-full md:w-1/2 p-4">
          <h1 className="text-2xl font-bold text-gray-800">Payment Details</h1>
          <Elements stripe={stripePromise}>
            <NewCheckoutForm2 />
          </Elements>
        </div>
      </section>
    </>
  );
}

export default CheckoutWrapper;
