import React from 'react'
import MixxerImage from '../../assets/images/Partnerships/mixxer-image.jpg'
import MixxerLogo from '../../assets/images/Partnerships/mixxer-logo.png'

const MIXXER = () => {
    return (
        <div id='mixxer'>
            <section className="bg-white">
                <div className="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
                <div className="md:mt-0">
                        <div className="flex max-w-sm mx-auto lg:max-w-none lg:mx-0 items-center align-middle justify-center">
                            <img className="h-80 object-contain items-center justify-center align-middle" src={MixxerLogo} alt="mixxer summer steam programs for kids to learn skills and have fun" />
                        </div>
                        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">Summer STEAM Experiences</h2>
                        <p className="mb-6 font-light text-gray-500 md:text-lg">Every Summer we work with organizations who share our mission for equiping children with fun and useful skills for their future. Since 2021, we have worked with MIXXER in Winston-Salem, NC to launch their summer STEAM experiences program!</p>
                        <div className="flex flex-col space-y-4 sm:flex-row sm:space-x-4 sm:space-y-2">
                            <a href="https://www.yesweekly.com/thearts/steam-experiences-summer-learning-with-mixxer-community-makerspace/article_11043c40-f7c9-11ec-b0e7-af5967ee4e60.html" target="_blank" rel="noopener noreferrer" className="inline-flex items-center border-2 bg-white text-black border-black hover:bg-black hover:text-white focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                              Read More!
                            </a>
                            <a href="https://triadmomsonmain.com/my-blog/summer-2021-mixxer-steam-education-experiences/" target="_blank" rel="noopener noreferrer" className="inline-flex items-center border-2 bg-white text-black border-black hover:bg-black hover:text-white focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                              Read More!
                            </a>

                            <a href="https://wsmixxer.coursestorm.com/browse" target="_blank" rel="noopener noreferrer" className="inline-flex items-center border-2 border-black hover:text-black text-white bg-black hover:bg-white focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center ">
                                Browse Summer 2023 Catalog
                                <svg className="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            </a>
                        </div>
                    </div>
                    <img className="w-full " src={MixxerImage} alt="mixxer summer steam programs for kids to learn skills and have fun" />

                </div>
            </section>
        </div>
    )
}

export default MIXXER