import React from "react";
import WFUNewsThumbnail from "../../assets/images/ByteSizeNews/WFUNewsThumbnail.png";
import TechDayNYCThumbnail from "../../assets/images/ByteSizeNews/TechDayNYC.jpg";
import WFUDemoDayThumbnail from "../../assets/images/ByteSizeNews/WFUDemoDay.png";
import FoundersPackThumbnail from "../../assets/images/ByteSizeNews/FoundersPack.png";
import ColumbiaEntrepreneurshipThumbnail from "../../assets/images/ByteSizeNews/ColumbiaEntrepreneurship.png";
import IdeasCityWSThumbnail from "../../assets/images/ByteSizeNews/IdeasCityWSThumbnail.png";
import InnovationExpoThumbnail from "../../assets/images/ByteSizeNews/InnovationExpo.png";
import ArticleCard from "./ArticleCard";

function ByteSizeArticles() {
  const ByteSizeNewsArticles = [
    {
      id: 1,
      title: "Wake Forest University News",
      description:
        "ByteSize Learning was featured in Wake Forest's news publication where our mission to help children learn was shared with the WFU community.",
      link: "https://news.wfu.edu/2021/09/13/bytesize-learning-helps-kids-learn-to-code-see-how-at-ideascityws/",
      link_two:
        "https://news.wfu.edu/2021/09/13/bytesize-learning-helps-kids-learn-to-code-see-how-at-ideascityws/",
      image: WFUNewsThumbnail,
      button_text: "Read The Article",
      alt: "wake forest news: ByteSize Learning helps kids learn to code, see how at IdeasCityWS",
      date: "September 13th, 2021",
      location: "Winston-Salem, North Carolina",
    },
    {
      id: 2,
      title: "IdeasCityWS Community Showcase",
      description:
        "ByteSize Learning was an exhibitor at the IdeasCityWS Community Showcase where we showcased our product and mission to help children learn technology skills like coding.",
      link: "https://ideascity.events.wfu.edu/",
      link_two:
        "https://www.uncsa.edu/mysa/announcements/20210930-ideascityws-festival.aspx",
      image: IdeasCityWSThumbnail,
      button_text: "Read About It",
      alt: "ByteSize Learning helps kids learn technology skills like coding, see how at IdeasCityWS",
      date: "October 2nd, 2021",
      location: "Winston-Salem, North Carolina",
    },
    {
      id: 3,
      title: "STEM & Innovation Expo at Kaleideum",
      description:
        "ByteSize Learning showcased our product and mission to help children learn technology skills like coding at the STEM & Innovation Expo at Kaleideum.",
      link: "https://www.facebook.com/events/kaleideum-north/nc-science-festival-stem-innovation-expo/3122862374592766/",
      link_two:
        "https://myemail.constantcontact.com/The-NC-Science-Festival-STEM---Innovation-Expo-Is-THIS-SATURDAY-at-Kaleideum-.html?soid=1102261797164&aid=ZAvrBwnDbFc",
      image: InnovationExpoThumbnail,
      button_text: "Read About It",
      alt: "ByteSize Learning helps kids learn technology skills like coding, see how at the STEM & Innovation Expo at Kaleideum",
      date: "April 9th, 2021",
      location: "Winston-Salem, North Carolina",
    },
    {
      id: 4,
      title: "WFU Entrepalooza Demo Day",
      description:
        "ByteSize Learning pitched to the community and investors at the WFU Entrepalooza Demo Day.",
      link: "https://www.youtube.com/watch?v=MBwIT_pzApw",
      link_two: "https://www.youtube.com/watch?v=MBwIT_pzApw",
      image: WFUDemoDayThumbnail,
      button_text: "Watch the Pitch",
      alt: "ByteSize Learning pitched to the community and investors at the WFU Entrepalooza Demo Day",
      date: "April 7th, 2022",
      location: "Winston-Salem, North Carolina",
    },
    {
      id: 5,
      title: "TechDay NYC Showcase",
      description:
        "ByteSize Learning showcased our product and mission to help children learn technology skills like coding at the TechDay NYC Showcase.",
      link: "https://techdayhq.com/new-york/attending",
      link_two: "https://techdayhq.com/new-york/attending",
      image: TechDayNYCThumbnail,
      button_text: "Read About It",
      alt: "ByteSize Learning helps kids learn technology skills like coding, see how at the TechDay NYC Showcase",
      date: "November 15th, 2022",
      location: "New York, New York",
    },
    {
      id: 6,
      title: "Columbia Entrepreneurship News",
      description:
        "ByteSize Learning was featured in Columbia's news publication where our mission to help children learn was shared with the Columbia community.",
      link: "https://entrepreneurship.columbia.edu/2023/03/31/columbia-engineer-founds-bytesize-learning/",
      link_two:
        "https://entrepreneurship.columbia.edu/2023/03/31/columbia-engineer-founds-bytesize-learning/",
      image: ColumbiaEntrepreneurshipThumbnail,
      button_text: "Read The Article",
      alt: "ByteSize Learning was featured in Columbia's news publication where our mission to help children learn was shared with the Columbia community.",
      date: "March 31st, 2023",
      location: "New York, New York",
    },
    {
      id: 7,
      title: "The Founders Pack Podcast",
      description:
        "Andrew Rust from ByteSize Learning was featured on the Founders Pack Podcast where he shared his story and mission to help children learn with Michael Devellano.",
      link: "https://www.youtube.com/watch?v=zdCPC_3-S5w",
      link_two: "https://www.youtube.com/watch?v=zdCPC_3-S5w",
      image: FoundersPackThumbnail,
      button_text: "Listen Now",
      alt: "Andrew Rust from ByteSize Learning was featured on the Founders Pack Podcast where he shared his story and mission to help children learn with Michael Devellano.",
      date: "April 8th, 2023",
      location: "New York, New York",
    },
  ];

  return (
    <div>
      <aside aria-label="Related articles" className="py-8 bg-white lg:py-16">
        <div className="px-4 mx-auto w-full max-w-screen-xl">
          <h2 className="mb-8 text-2xl font-bold text-gray-900">
            ByteSize in the Community and in the News
          </h2>
          <div id="animation-carousel" data-carousel="slide">
            <div className="relative overflow-hidden rounded-lg h-[480px]">
              <div
                className="hidden bg-white duration-700 ease-in-out"
                data-carousel-item
              >
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-3">
                  {ByteSizeNewsArticles.slice(0, 3).map((event) => (
                    <ArticleCard
                      key={event.id}
                      title={event.title}
                      description={event.description}
                      link={event.link}
                      link_two={event.link_two}
                      image={event.image}
                      button_text={event.button_text}
                      alt={event.alt}
                      date={event.date}
                    />
                  ))}
                </div>
              </div>
              <div
                className="hidden bg-white duration-700 ease-in-out"
                data-carousel-item
              >
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-3">
                  {ByteSizeNewsArticles.slice(3, 6).map((event) => (
                    <ArticleCard
                      key={event.id}
                      title={event.title}
                      description={event.description}
                      link={event.link}
                      link_two={event.link_two}
                      image={event.image}
                      button_text={event.button_text}
                      alt={event.alt}
                      date={event.date}
                    />
                  ))}
                </div>
              </div>
              <div
                className="hidden bg-white duration-700 ease-in-out"
                data-carousel-item
              >
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-3">
                  {ByteSizeNewsArticles.slice(6, 9).map((event) => (
                    <ArticleCard
                      key={event.id}
                      title={event.title}
                      description={event.description}
                      link={event.link}
                      link_two={event.link_two}
                      image={event.image}
                      button_text={event.button_text}
                      alt={event.alt}
                      date={event.date}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center mt-2">
              <button
                type="button"
                className="flex justify-center items-center mr-4 h-full cursor-pointer group focus:outline-none"
                data-carousel-prev
              >
                <span className="text-gray-500 hover:text-gray-700 ">
                  <svg
                    className="w-7 h-7"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="hidden">Previous</span>
                </span>
              </button>
              <button
                type="button"
                className="flex justify-center items-center h-full cursor-pointer group focus:outline-none"
                data-carousel-next
              >
                <span className="text-gray-500 hover:text-gray-700 ">
                  <svg
                    className="w-7 h-7"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="hidden">Next</span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </aside>
    </div>
  );
}

export default ByteSizeArticles;
