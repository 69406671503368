import React from "react";
import FocusedKid from "./../../assets/images/StudentImages/focused-kid.jpg"

function BuddiesHero() {
    return (
        <div>
            <img className="w-full object-cover md:h-[60vh] xs:h-[65vh] bg-cover bg-center" src={FocusedKid} alt="Children learning technology with ByteSize Learning for kids" />
            <div className="absolute top-0 left-0 w-full md:h-[60vh] xs:h-[65vh] bg-black bg-opacity-40" />
            <section className="absolute top-20 w-full bg-transparent object-center items-center">
                <div className="max-w-screen-xl px-4 py-8 mx-auto text-center lg:py-16 lg:px-6">
                    <dl className="grid max-w-screen-md gap-8 mx-auto sm:grid-cols-3 text-white mt-20">
                        <div className="flex flex-col items-center justify-center col-span-3">
                            <dt className="mb-2 text-3xl md:text-4xl font-extrabold">Making a difference - one child at a time!</dt>
                            <dd className="font-light text-gray-100">We are investing in the future of education</dd>
                        </div>

                    </dl>
                </div>
            </section>
        </div>
    );
}

export default BuddiesHero;