import React from "react";
import NewsletterCTA from "./UniversalComponents/NewsletterCTA";
import ByteSizeArticles from "./ByteSizeNews/ByteSizeArticles";
import ByteSizeBlogsCTA from "./ByteSizeNews/ByteSizeBlogsCTA";

function ByteSizeNews() {
  return (
    <div id="bytesizenews" className="pt-16">
      <ByteSizeBlogsCTA />
      <ByteSizeArticles />
      <NewsletterCTA />
    </div>
  );
}

export default ByteSizeNews;
