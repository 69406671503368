import React, { useRef, useState, useEffect } from "react";
import emailjs from '@emailjs/browser';


function ContactForm() {
    const form = useRef();

    const [show, toggleShow] = useState(false);

    useEffect(() => {
        if (!show) {
            form.current.reset();
        }
    }, [show]);

    const sendEmail = async (e) => {
        e.preventDefault();
        if (form.current.checkValidity()) {
            try {
                await emailjs.sendForm('service_4h1pc9c', 'template_1t50fqq', form.current, 'JoQcn25Gezs4At60T');
                toggleShow(true);
            } catch (error) {
                console.error(error);
            }
        } else {
            form.current.reportValidity()
        }
    };    
    return (
        <div id="contact-form">
            <div className="bg-gradient-to-r from-dodger to-midnight bg-no-repeat bg-cover bg-center bg-blend-multiply">
                <div className="px-4 lg:pt-24 pt-8 pb-72 lg:pb-80 mx-auto max-w-screen-sm text-center lg:px-6">
                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-white mt-16">Contact Us</h2>
                    <p className="mb-16 font-light text-gray-200 sm:text-xl">We love to hear from our customers, reach out with a question, comment, suggestion, or just to say hello!</p>
                </div>
            </div>
            <div className="py-16 px-4 mx-auto -mt-96 max-w-screen-xl sm:py-24 lg:px-6">
                <form ref={form} onSubmit={sendEmail} className="grid grid-cols-1 gap-8 p-6 mx-auto mb-16 max-w-screen-md bg-white rounded-lg border border-gray-200 shadow-sm lg:mb-28  sm:grid-cols-2">
                    <div>
                        <label htmlFor="first-name" className="block mb-2 text-sm font-medium text-gray-900">First Name</label>
                        <input type="text" name='first-name' id="first-name" className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500     " placeholder="ByteSize" required />
                    </div>
                    <div>
                        <label htmlFor="last-name" className="block mb-2 text-sm font-medium text-gray-900">Last Name</label>
                        <input type="text" name='last-name' id="last-name" className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500     " placeholder="Learner" required />
                    </div>
                    <div>
                        <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900">Your email</label>
                        <input type="email" id="email" name='email' className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5     " placeholder="bytesizelearner@gmail.com" required />
                    </div>
                    <div>
                        <label htmlFor="phone-number" className="block mb-2 text-sm font-medium text-gray-900">Phone Number</label>
                        <input type="text" name='phone-number' id="phone-number" className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500     " placeholder="(123) 456-7890" required />
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="message" className="block mb-2 text-sm font-medium text-gray-900">Your message</label>
                        <textarea name='message' id="message" rows="6" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500    " placeholder="Let us know how we can help"></textarea>
                        <p className="mt-4 text-sm text-gray-500">By submitting this form you agree to our Terms and Conditions which explains how we may collect, use and disclose your personal information including to third parties.</p>
                    </div>

                    <div>
                        <button
                            type="submit"
                            onClick={sendEmail}
                            className={`py-3 px-5 text-sm font-medium text-center border-2 border-dodger hover:bg-white hover:text-dodger duration-200 text-white rounded-lg bg-dodger sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300    ${show ? 'hidden' : ''}`}
                        >
                            Send Message
                        </button>
                        <p
                            className={`py-3 px-5 text-sm font-medium text-center hover:bg-white hover:text-dodger duration-200 text-dodger rounded-lg bg-white sm:w-fit  focus:ring-4 focus:outline-none focus:ring-primary-300    ${!show ? 'hidden' : ''}`}
                        >
                            Your message has been sent! Thank you!
                        </p>
                    </div>


                </form>
                <div className="space-y-8 text-center md:grid md:grid-cols-1 lg:grid-cols-1 md:gap-12 md:space-y-0">
                    <div>
                        <div className="flex justify-center items-center mx-auto mb-4 w-10 h-10 bg-gray-100 rounded-lg lg:h-16 lg:w-16">
                            <svg className="w-5 h-5 text-dodger lg:w-8 lg:h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path><path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path></svg>
                        </div>
                        <p className="mb-2 text-xl font-bold">Email us:</p>
                        <p className="mb-3 text-gray-500">Email us for queries, general assitance, or partnership opportunities.</p>
                        <a href="mailto:abc@example.com" className="font-semibold text-primary-600 hover:underline">andrew@bytesizelearning.org</a>
                    </div>
                    {/* <div>
                        <div className="flex justify-center items-center mx-auto mb-4 w-10 h-10 bg-gray-100 rounded-lg lg:h-16 lg:w-16">
                            <svg className="w-5 h-5 text-dodger lg:w-8 lg:h-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"></path></svg>
                        </div>
                        <p className="mb-2 text-xl font-bold">Call us:</p>
                        <p className="mb-3 text-gray-500">Call us to speak to a member of our team. We are always happy to help.</p>
                        <span className="font-semibold text-primary-600">+1 (919) 400-9171</span>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default ContactForm;
