import React from "react";
import HeroVideo from "../../assets/videos/HomePageHeroVideo.mp4";
import ArrowDown from "../../assets/icons/arrow-down.png";

function Hero() {
  return (
    <section>
      <div className="w-full h-[90vh] max-h-[1000px] content-center">
        <video
          className="w-full h-full object-cover z-0 max-h-[1000px]"
          src={HeroVideo}
          playsInline
          autoPlay
          loop
          muted
        />
        <div className="absolute top-0 left-0 w-full h-[90vh] bg-black bg-opacity-40 max-h-[1000px]" />
        <div className="absolute top-0 w-full h-[90vh] flex items-center justify-left z-0 bg-transparent max-h-[1000px]">
          <div className="grid w-full lg:gap-8 xl:gap-0 lg:py-16 bg-transparent content-around mx-20 max-h-[1000px]">
            <div className="mr-auto place-self-center lg:col-span-7">
              <h1 className="max-w-full mb-6 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl text-white">
                Technology Classes for Kids
              </h1>
              <p className="max-w-2xl mb-6 font-light lsg:mb-8 md:text-lg lg:text-xl text-white">
                Everything from programming to game design for ages 8 to 18
              </p>
              <a
                href="/classes"
                className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center border-2 border-dodger text-white rounded-lg bg-dodger hover:bg-gray-50 duration-200 hover:border-solid hover:border-2 hover:text-dodger hover:border-dodger focus:ring-4 focus:ring-dodger "
              >
                Explore Classes
                <svg
                  className="w-5 h-5 ml-2 -mr-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </a>
            </div>
          </div>

          <div className="absolute w-full bottom-0 flex items-center justify-center z-10 bg-transparent animate-bounce place-self-center mb-5">
            
              <div>
              <a href="#scroll-down">
                <p className="text-white font-light mx-6">Scroll Down</p>
              </a>
              </div>
              <div>
              <a href="#scroll-down">
                <img
                  className="w-8 h-8"
                  viewBox="0 0 24 24"
                  src={ArrowDown}
                  alt="arrow down to learn more about ByteSize Learning for kids"
                />
              </a>
              </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
