import React from "react";

import BuddiesHero from "./ByteSizeBuddies/BuddiesHero";
import BuddiesAbout from "./ByteSizeBuddies/BuddiesAbout";
import BuddiesCTA from "./ByteSizeBuddies/BuddiesCTA";
import BuddiesFAQS from "./ByteSizeBuddies/BuddiesFAQS";
import NewsletterCTA from "./UniversalComponents/NewsletterCTA";

function ByteSizeBuddies() {
    return (
        <div id='bytesizebuddies'>
            <BuddiesHero />
            <BuddiesAbout />
            {/* FUTURE: TESTMONIALS & IMAGES */}
            <BuddiesCTA />
            {/* FUTURE: PRESS + NEWS */}
            <BuddiesFAQS />
            <NewsletterCTA />
        </div>
    );
}

export default ByteSizeBuddies;