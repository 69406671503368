/* eslint-disable */
import React from "react";

import NewCheckoutForm from "./NewCheckoutForm.jsx";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import ByteSizeLogo from "../assets/bytesize-logo.png";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe("pk_live_51HztwsLs3GQydstigq4wHAHrt0S6DwELL5m2c6GYgm8AVAdxZz2KSThwm7YZCNN4VVrXAWycdLkBx2oQFNrfXnAL00efLDikhk");

function CheckoutWrapper() {

    const tabs = document.querySelectorAll('[data-tabs-target]');

    tabs.forEach(tab => {
        tab.addEventListener('click', () => {
            const target = document.querySelector(tab.dataset.tabsTarget);

            // hide all tab contents
            document.querySelectorAll('[role="tabpanel"]').forEach(content => {
                content.classList.add('hidden');
            });

            // show the target tab content
            target.classList.remove('hidden');

            // mark the clicked tab as active
            tabs.forEach(t => {
                t.classList.remove('active');
            });
            tab.classList.add('active');
        });
    });


    return (
        <section className="bg-white">
            <div className="pt-8 px-4 mx-auto max-w-screen-xl sm:pt-16 lg:px-6">
                <div className="mx-auto max-w-screen-sm text-center">

                    <span>
                        <h1 className="text-3xl font-bold text-gray-800">Checkout</h1>
                        <h3 className="text-lg font-light text-gray-800">Let us know if you are having any trouble purchasing a class</h3>
                    </span>
                </div>
            </div>

            <div className="gap-8 py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
                <div className="sticky top-0">
                    <div className="mb-4 border-b border-gray-200 ">
                        <ul className="flex flex-wrap -mb-px text-sm font-medium text-center" id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
                            <li className="mr-2" role="presentation">
                                <button className="inline-block p-4 rounded-t-lg {{ $tab === 'single_byte_plan' ? 'border-b-2 border-dodger ' : 'border-b-2 border-transparent' }}" id="single_byte_plan-tab" data-tabs-target="#single_byte_plan" type="button" role="tab" aria-controls="single_byte_plan" aria-selected="{{ $tab === 'single_byte_plan' ? 'true' : 'false' }}">Single Byte Plan</button>
                            </li>
                            <li className="mr-2" role="presentation">
                                <button className="inline-block p-4 rounded-t-lg {{ $tab === 'kilobyte_plan' ? 'border-b-2 border-dodger ' : 'border-b-2 border-transparent' }}" id="kilobyte_plan-tab" data-tabs-target="#kilobyte_plan" type="button" role="tab" aria-controls="kilobyte_plan" aria-selected="{{ $tab === 'kilobyte_plan' ? 'true' : 'false' }}">KiloByte Plan</button>
                            </li>
                            <li className="mr-2" role="presentation">
                                <button className="inline-block p-4 rounded-t-lg {{ $tab === 'megabyte_plan' ? 'border-b-2 border-dodger ' : 'border-b-2 border-transparent' }}" id="megabyte_plan-tab" data-tabs-target="#megabyte_plan" type="button" role="tab" aria-controls="megabyte_plan" aria-selected="{{ $tab === 'megabyte_plan' ? 'true' : 'false' }}">MegaByte Plan</button>
                            </li>
                            <li role="presentation">
                                <button className="inline-block p-4 rounded-t-lg {{ $tab === 'gigabyte_plan' ? 'border-b-2 border-dodger ' : 'border-b-2 border-transparent' }}" id="gigabyte_plan-tab" data-tabs-target="#gigabyte_plan" type="button" role="tab" aria-controls="gigabyte_plan" aria-selected="{{ $tab === 'megabyte_plan' ? 'true' : 'false' }}">GigaByte Plan</button>
                            </li>
                        </ul>
                    </div>
                    <div id="myTabContent">
                        <div className="hidden p-4 rounded-lg bg-gray-50" id="single_byte_plan" role="tabpanel" aria-labelledby="single_byte_plan-tab">
                            <div className="flex items-center justify-between mb-4">
                                <img src={ByteSizeLogo} alt="ByteSize Learning - Interactive technology education for kids" className="h-10 w-10 mr-2" />
                                <h3 className="text-xl font-bold text-gray-800">Single Byte Plan</h3>
                                <span className="flex items-center">
                                    <h3 className="text-xl font-light text-gray-800 m-2">1 Class</h3>
                                    <div className="border-l-2 border-gray-500 h-12 m-2"></div>
                                    <h3 className="text-xl font-light text-gray-800 m-2">$249</h3>
                                </span>
                            </div>
                            <hr className="bg-gray-600" />
                            <h3 className="text-xl font-bold text-gray-800 mt-4">ByteSize Course Benefits</h3>
                            <ul className="my-6 lg:mb-0 space-y-4">
                                <li className="flex space-x-2.5">
                                    <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                    <span className="leading-tight text-gray-500">Creative problem solving</span>
                                </li>
                                <li className="flex space-x-2.5">
                                    <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                    <span className="leading-tight text-gray-500">Professional skills</span>
                                </li>
                                <li className="flex space-x-2.5">
                                    <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                    <span className="leading-tight text-gray-500">Fun, self-driven classes</span>
                                </li>
                                <li className="flex space-x-2.5">
                                    <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                    <span className="leading-tight text-gray-500">Relatable and qualified instructors</span>
                                </li>
                                <li className="flex space-x-2.5">
                                    <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                    <span className="leading-tight text-gray-500">Project-based curriculum</span>
                                </li>
                            </ul>

                        </div>
                        <div className="hidden p-4 rounded-lg bg-gray-50" id="kilobyte_plan" role="tabpanel" aria-labelledby="kilobyte_plan-tab">
                            <div className="flex items-center justify-between mb-4">
                                <img src={ByteSizeLogo} alt="ByteSize Learning - Interactive technology education for kids" className="h-10 w-10 mr-2" />
                                <h3 className="text-xl font-bold text-gray-800">KiloByte Plan</h3>
                                <span className="flex items-center">
                                    <h3 className="text-xl font-light text-gray-800 m-2">2 Classes</h3>
                                    <div className="border-l-2 border-gray-500 h-12 m-2"></div>
                                    <h3 className="text-xl font-light text-gray-800 m-2">$475</h3>
                                </span>
                            </div>
                            <hr className="bg-gray-600" />
                            <h3 className="text-xl font-bold text-gray-800 mt-4">ByteSize Course Benefits</h3>
                            <ul className="my-6 lg:mb-0 space-y-4">
                                <li className="flex space-x-2.5">
                                    <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                    <span className="leading-tight text-gray-500">Creative problem solving</span>
                                </li>
                                <li className="flex space-x-2.5">
                                    <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                    <span className="leading-tight text-gray-500">Professional skills</span>
                                </li>
                                <li className="flex space-x-2.5">
                                    <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                    <span className="leading-tight text-gray-500">Fun, self-driven classes</span>
                                </li>
                                <li className="flex space-x-2.5">
                                    <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                    <span className="leading-tight text-gray-500">Relatable and qualified instructors</span>
                                </li>
                                <li className="flex space-x-2.5">
                                    <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                    <span className="leading-tight text-gray-500">Project-based curriculum</span>
                                </li>
                            </ul>
                        </div>
                        <div className="hidden p-4 rounded-lg bg-gray-50" id="megabyte_plan" role="tabpanel" aria-labelledby="megabyte_plan-tab">
                            <div className="flex items-center justify-between mb-4">
                                <img src={ByteSizeLogo} alt="ByteSize Learning - Interactive technology education for kids" className="h-10 w-10 mr-2" />
                                <h3 className="text-xl font-bold text-gray-800">MegaByte Plan</h3>
                                <span className="flex items-center">
                                    <h3 className="text-xl font-light text-gray-800 m-2">4 Classes</h3>
                                    <div className="border-l-2 border-gray-500 h-12 m-2"></div>
                                    <h3 className="text-xl font-light text-gray-800 m-2">$899</h3>
                                </span>
                            </div>
                            <hr className="bg-gray-600" />
                            <h3 className="text-xl font-bold text-gray-800 mt-4">ByteSize Course Benefits</h3>
                            <ul className="my-6 lg:mb-0 space-y-4">
                                <li className="flex space-x-2.5">
                                    <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                    <span className="leading-tight text-gray-500">Creative problem solving</span>
                                </li>
                                <li className="flex space-x-2.5">
                                    <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                    <span className="leading-tight text-gray-500">Professional skills</span>
                                </li>
                                <li className="flex space-x-2.5">
                                    <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                    <span className="leading-tight text-gray-500">Fun, self-driven classes</span>
                                </li>
                                <li className="flex space-x-2.5">
                                    <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                    <span className="leading-tight text-gray-500">Relatable and qualified instructors</span>
                                </li>
                                <li className="flex space-x-2.5">
                                    <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                    <span className="leading-tight text-gray-500">Project-based curriculum</span>
                                </li>
                            </ul>
                        </div>
                        <div className="hidden p-4 rounded-lg bg-gray-50" id="gigabyte_plan" role="tabpanel" aria-labelledby="gigabyte_plan-tab">
                            <div className="flex items-center justify-between mb-4">
                                <img src={ByteSizeLogo} alt="ByteSize Learning - Interactive technology education for kids" className="h-10 w-10 mr-2" />
                                <h3 className="text-xl font-bold text-gray-800">GigaByte Plan</h3>
                                <span className="flex items-center">
                                    <h3 className="text-xl font-light text-gray-800 m-2">8 Classes</h3>
                                    <div className="border-l-2 border-gray-500 h-12 m-2"></div>
                                    <h3 className="text-xl font-light text-gray-800 m-2">$1699</h3>
                                </span>
                            </div>
                            <hr className="bg-gray-600" />
                            <h3 className="text-xl font-bold text-gray-800 mt-4">ByteSize Course Benefits</h3>
                            <ul className="my-6 lg:mb-0 space-y-4">
                                <li className="flex space-x-2.5">
                                    <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                    <span className="leading-tight text-gray-500">Creative problem solving</span>
                                </li>
                                <li className="flex space-x-2.5">
                                    <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                    <span className="leading-tight text-gray-500">Professional skills</span>
                                </li>
                                <li className="flex space-x-2.5">
                                    <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                    <span className="leading-tight text-gray-500">Fun, self-driven classes</span>
                                </li>
                                <li className="flex space-x-2.5">
                                    <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                    <span className="leading-tight text-gray-500">Relatable and qualified instructors</span>
                                </li>
                                <li className="flex space-x-2.5">
                                    <svg className="flex-shrink-0 w-5 h-5 text-dodger" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path></svg>
                                    <span className="leading-tight text-gray-500">Project-based curriculum</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div id="stripe" className="mt-10 bg-gray-50 px-6 pt-8 lg:mt-0">
                    <Elements stripe={stripePromise}>
                        <NewCheckoutForm />
                    </Elements>
                </div >
            </div>
            <div className="px-4 max-w-screen-xl lg:py-4 lg:px-4">
                <div className="justify-between items-center text-gray-600 rounded-lg lg:flex lg:gap-12 md:p-12">
                    <div className="w-full">
                        <p className="font-light text-gray-400 sm:text-xl">If you are unsatisfied with your class in anyway, you are elgible for a refund. Please reach out to andrew@bytesizelearning if you have any questions or concerns.</p>
                    </div>
                </div>
            </div>
        </section>

    );
}

export default CheckoutWrapper;
