import React from "react";
import AndrewHeadshot from "../../assets/images/TeamHeadshots/AndrewAvatar.jpg";

function BookDemo() {
    return (
        <div id="book-demo">
            <div className="bg-gray-50 py-16">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
                        <div className="sm:flex sm:items-center sm:justify-between">
                            <div className="sm:flex sm:space-x-5">
                                <div className="flex-shrink-0">
                                    <img className="h-24 w-24 rounded-full" src={AndrewHeadshot} alt="Product Demo Instructor" />
                                </div>
                                <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                                    <p className="text-2xl font-bold text-gray-900">Book a Product Demo</p>
                                    <p className="text-xl font-medium text-gray-500 mt-2">with Andrew Rust</p>
                                    <p className="text-sm font-medium text-gray-500 mt-1"><em>Founder & Instructor at ByteSize Learning</em></p>
                                </div>
                            </div>
                            <div className="mt-5 sm:mt-0 sm:ml-4">
                                <a href="https://calendly.com/andrew-rust" target="_blank" rel="noopener noreferrer" className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-dodger hover:bg-dodger">
                                    Book a Demo
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BookDemo;