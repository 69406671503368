import React from "react";
import ClassStructure from "./HomeComponents/ClassStructure";
import AllClasses from "./HomeComponents/AllClasses";
//import 'flowbite/dist/flowbite.min.js';

function Classes() {
    return (
        <div id="classes">
            <ClassStructure />
            <AllClasses />
        </div>
    );
}

export default Classes;