import React from "react";
import Hero from "./HomeComponents/Hero";
import HeadlineFeature from "./HomeComponents/HeadlineFeature";
import Testimonials from "./HomeComponents/Testimonials";
import NewsLetterCTA from "./UniversalComponents/NewsletterCTA";
import ClassStructure from "./HomeComponents/ClassStructure";
import AllClasses from "./HomeComponents/AllClasses";
import BookDemo from "./UniversalComponents/BookDemo";
//import 'flowbite/dist/flowbite.min.js';

function Home() {
    return (
        <div id='/' className="flex-grow">
            <Hero />
            <div id ='scroll-down'>
                <HeadlineFeature />
            </div>
            <AllClasses />
            <BookDemo />
            <ClassStructure />
            <Testimonials />
            <NewsLetterCTA />
        </div>
    );
}

export default Home;